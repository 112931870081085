import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';
import DownloadingIcon from '@mui/icons-material/Downloading';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UpdateIcon from '@mui/icons-material/Update';
import LyricsIcon from '@mui/icons-material/Lyrics';

export default function TemporaryDrawer({menuState, toggleDrawer, csvuploadClick, jsonuploadClick, downloadJSON, uploadVideo}) {
  // const [open, setOpen] = React.useState(state);

  return (
    <div>
        <React.Fragment key='left'>
        <Drawer
          anchor='left'
          open={menuState}
          onClose={toggleDrawer}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
          >
            <List>
              
                <ListItem key='upload-csv' disablePadding>
                  <ListItemButton onClick={csvuploadClick}>
                    <ListItemIcon>
                      <UploadFileIcon />
                    </ListItemIcon>
                    <ListItemText primary='upload csv file' />
                  </ListItemButton>
                </ListItem>

                <ListItem key='upload-json' disablePadding>
                  <ListItemButton onClick={jsonuploadClick}>
                    <ListItemIcon>
                      <UpdateIcon />
                    </ListItemIcon>
                    <ListItemText primary='upload checkpoint' />
                  </ListItemButton>
                </ListItem>

                <ListItem key='download-checkpoint' disablePadding>
                  <ListItemButton onClick={downloadJSON}>
                    <ListItemIcon>
                      <DownloadingIcon />
                    </ListItemIcon>
                    <ListItemText primary='download checkpoint' />
                  </ListItemButton>
                </ListItem>
              
            </List>
            <Divider />
                <ListItem key='upload video' disablePadding>
                  <ListItemButton onClick={uploadVideo}>
                    <ListItemIcon>
                      <LyricsIcon />
                    </ListItemIcon>
                    <ListItemText primary='upload video' />
                  </ListItemButton>
                </ListItem>
          </Box>
        </Drawer>
        </React.Fragment>
    </div>
  );
}