import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { FormControl, FormControlLabel, RadioGroup, Radio, Stack, Grid, Box, Button, useMediaQuery, TextField } from '@mui/material';
// import Card from '../cards/Card';
import { visuallyHidden } from '@mui/utils';
import { OutlinedBox } from './OutlinedBox';
// project imports
import Chip from '../cards/Chip';
import SubCard from '../cards/SubCard';
import { pink } from '@mui/material/colors';
// assets

import _ from 'lodash';

const colorStrOptions = ['primary', 'secondary', 'error', 'info', 'success', 'warning'];
// api functions
// import { getClients, getAuthStatusCard } from './apicalls';

// ==============================|| CUSTOMER LIST ||============================== //

const TemporalDRTags = [[{id: 'Temporal', category: 'rs', lvl: 0}], [{id: 'Async', category: 'rs', lvl: 1}],
    [{id: 'Sync', category: 'rs', lvl: 1}],
    [{id: 'Before', category: 'rs', lvl: 2},
    {id: 'After', category: 'rs', lvl: 2}],
];
const ContingencyDRTags = [[{id: 'Contingency', category: 'rs', lvl: 1}], [{id: 'Causing', category: 'rs', lvl: 2},{id: 'CausedBy', category: 'rs', lvl: 2}],
    [{id: 'Justifying', category: 'rs', lvl: 2}, {id: 'JustifiedBy', category: 'rs', lvl: 2}],
    [{id: 'Motivating', category: 'rs', lvl: 2}, {id: 'MotivatedBy', category: 'rs', lvl: 2}],
    [{id: 'Conditioning', category: 'rs', lvl: 2}, {id: 'ConditionedBy', category: 'rs', lvl: 2}],
    [{id: 'NegConditioning', category: 'rs', lvl: 2}, {id: 'NegConditioned', category: 'rs', lvl: 2}],
    [{id: 'Purposing', category: 'rs', lvl: 2}, {id: 'Purposed', category: 'rs', lvl: 2}],
    [{id: 'Enabling', category: 'rs', lvl: 2}, {id: 'Enabled', category: 'rs', lvl: 2}],
    [{id: 'Evaluating', category: 'rs', lvl: 2}, {id: 'Evaluated', category: 'rs', lvl: 2}]
];
const ComparisonDRTags = [[{id: 'Comparison', category: 'rs', lvl: 1}], [{id: 'Contrast', category: 'rs', lvl: 2}],
    [{id: 'Similarity', category: 'rs', lvl: 2}],
    [{id: 'Conceding', category: 'rs', lvl: 2}, {id: 'ConcededBy', category: 'rs', lvl: 2}],
];
const ExpansionDRTags = [[{id: 'Expanding', category: 'rs', lvl: 1}, {id: 'Expanded', category: 'rs', lvl: 1}],
    [{id: 'Instantiating', category: 'rs', lvl: 2}, {id: 'Instantiated', category: 'rs', lvl: 2}],
    [{id: 'GivingDetails', category: 'rs', lvl: 2}, {id: 'GivenDetails', category: 'rs', lvl: 2}],
    [{id: 'Substituting', category: 'rs', lvl: 2}, {id: 'Substituted', category: 'rs', lvl: 2}],
    [{id: 'Restate/Equal', category: 'rs', lvl: 2}, {id: 'TakeStock', category: 'rs', lvl: 2}],
    [{id: 'Disjunction', category: 'rs', lvl: 2}],
    [{id: 'Conjunction', category: 'rs', lvl: 2}],
    [{id: 'Excluding', category: 'rs', lvl: 2}, {id: 'Excluded', category: 'rs', lvl: 2}],
    [{id: 'GivingManner', category: 'rs', lvl: 2}, {id: 'GivenManner', category: 'rs', lvl: 2}]
];

const DRTags = [['temporal', TemporalDRTags],
    ['contingency', ContingencyDRTags],
    ['comparison', ComparisonDRTags], 
    ['expansion', ExpansionDRTags]
];

const StatementsDATags = [{id: 'Statement', category: 'da', lvl: 2},
    {id: 'Opinion', category: 'da', lvl: 2}
];

const CommuniStatusDATags = [{id: 'Self-talk', category: 'da', lvl: 2},
    {id: 'Abandoned', category: 'da', lvl: 2},
];

const BackCommFuncDATags = [{id: 'Answer', category: 'da', lvl: 2},
    {id: 'Stalling', category: 'da', lvl: 2},
    {id: 'Incomplete', category: 'da', lvl: 2},
    {id: 'Accept', category: 'da', lvl: 2},
    {id: 'Reject', category: 'da', lvl: 2},
    {id: 'Collab-Completion', category: 'da', lvl: 2},
    {id: 'Appreciation', category: 'da', lvl: 2},
    {id: 'Downplayer', category: 'da', lvl: 2},
    {id: 'Sympathy', category: 'da', lvl: 2},
    {id: 'Acknowledge', category: 'da', lvl: 2},
    {id: 'Sig-Non-Understanding', category: 'da', lvl: 2},
];
const ForwardCommFuncDATags = [{id: 'Task-M', category: 'da', lvl: 2},
    {id: 'Offer', category: 'da', lvl: 2},
    {id: 'Action-Directive', category: 'da', lvl: 2},
    {id: 'Commit', category: 'da', lvl: 2},
    {id: 'Propose', category: 'da', lvl: 2},
    {id: 'Q/Info-Request', category: 'da', lvl: 2},
    {id: 'Open-Q', category: 'da', lvl: 2},
    {id: 'Rhetorical-Q', category: 'da', lvl: 2},
    {id: 'Apology', category: 'da', lvl: 2},
    {id: 'Thanking', category: 'da', lvl: 2},
    {id: 'Exclamation', category: 'da', lvl: 2},
    {id: 'Explicit-Performative', category: 'da', lvl: 2},
    {id: 'Welcome', category: 'da', lvl: 2},
];
const InfoLvlDATags = [{id: 'Greeting', category: 'da', lvl: 2},
    {id: 'Correction', category: 'da', lvl: 2},
    {id: 'Conventional-Closing', category: 'da', lvl: 2},
];
const OtherDATags = [{id: 'Hedge', category: 'da', lvl: 2},
    {id: 'Joke', category: 'da', lvl: 2},
];

const DATags = [['statements', StatementsDATags],
    ['communicative status', CommuniStatusDATags], 
    ['backward communicative func', BackCommFuncDATags], 
    ['forward communicative func', ForwardCommFuncDATags], 
    ['information lvl', InfoLvlDATags], 
    ['others', OtherDATags]
];

const DDARelationCard = ({ curTags, curMemo }) => {
    // console.log("curTags: ", curTags);
    // console.log("curMemo: ", curMemo);
    const [selected, setSelected] = useState(curTags.current);
    const [newMemo, setNewMemo] = useState(curMemo.current);
    // const handleChange = (event) => {
    //     setSelectedValue(event.target.value);
    // };
    function handleSelectionChanged(id) {
        // treat state as immutable
        // React only does a shallow comparison so we need a new Set

        const newSet = new Set(selected);
        if (newSet.has(id)) newSet.delete(id);
        else newSet.add(id);
        setSelected(Array.from(newSet));
        curTags.current = Array.from(newSet);
    }


    const handleMemoChange = (e) => {
        // setNewMemo(e.target.value);
        curMemo.current = e.target.value;
        setNewMemo(e.target.value);
    };

    return (
            <FormControl sx={{width: '800px'}} onSubmit={(e) => {e.preventDefault();}}>
                <Grid container direction="row" sx={{width: '100%'}}>
                    <Grid item xs={6} md={6} lg={6} sx={{width: '50%'}}>
                        {DRTags.map(drgroup => (
                            <OutlinedBox label={drgroup[0]} >
                                {drgroup[1].map((c) => {
                                    if(c.length > 1){
                                        return (
                                            <Box>
                                                <Chip
                                                    key={c[0].id}
                                                    onClick={() => handleSelectionChanged(c[0].id)}
                                                    variant={selected.includes(c[0].id) ? "filled" : "outlined"}
                                                    chipcolor={c[0].lvl === 2 ? 'success' : 'primary'}
                                                    label={c[0].id}
                                                />
                                                <Chip
                                                    key={c[1].id}
                                                    onClick={() => handleSelectionChanged(c[1].id)}
                                                    variant={selected.includes(c[1].id) ? "filled" : "outlined"}
                                                    chipcolor={c[0].lvl === 2 ? 'success' : 'primary'}
                                                    label={c[1].id}
                                                />                                            
                                            </Box>
                                        )
                                    } else {
                                        return (
                                            <Chip
                                                key={c[0].id}
                                                onClick={() => handleSelectionChanged(c[0].id)}
                                                variant={selected.includes(c[0].id) ? "filled" : "outlined"}
                                                chipcolor={c[0].lvl === 2 ? 'success' : 'primary'}
                                                label={c[0].id}
                                            />
                                        )                                        
                                    }

                                }
                                )}
                            </OutlinedBox>
                            )
                        )}
                            
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        {DATags.map(dagroup => (
                            <OutlinedBox label={dagroup[0]}>
                                {dagroup[1].map((c) => (
                                <Chip
                                    key={c.id}
                                    onClick={() => handleSelectionChanged(c.id)}
                                    variant={selected.includes(c.id) ? "filled" : "outlined"}
                                    chipcolor='error'
                                    label={c.id}
                                />

                                ))}
                            </OutlinedBox>
                        ))}
                    </Grid>                    
                </Grid>
                <Grid container sx={{width: '100%'}}>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 0.5, width: '75ch'},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <TextField id="outlined-basic" label="re-phrasing memo" variant="outlined" value={newMemo} onChange={(e) => handleMemoChange(e)}/>
                    </Box>
                </Grid>
{/*                <Grid item>
                    <Box mb={0.5} ml={0.5}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Button type="button" variant="outlined" size="small" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" size="small" onClick={(e) => handleSubmit(curTags, curMemo)}>
                            Save
                        </Button>
                    </Stack>
                    </Box>
                </Grid>*/}
            </FormControl>
    );
};

// DDARelationCard.propTypes = {
//     curTags: PropTypes.array,
//     curMemo: PropTypes.string,
//     setSelected: PropTypes.func,
//     setNewMemo: PropTypes.func,
//     // handleCancel: PropTypes.func,
//     // handleSubmit: PropTypes.func,
//     ddaTags: PropTypes.array
// };

export default DDARelationCard;
