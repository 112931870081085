import React, { memo } from 'react';
import { Handle } from 'reactflow';
import styled from 'styled-components';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

const Node = styled.div`
  padding: 10px 20px;
  border-radius: 5px;
  // background: ${(props) => props.theme.nodeBg};
  color: ${(props) => props.theme.nodeColor};
  border: 1px solid ${(props) => (props.selected ? props.theme.primary : props.theme.nodeBorder)};
  width: 300;
  height: 110;

  .react-flow__handle {
    background: ${(props) => props.theme.primary};
    width: 0px;
    height: 0px;
    border-radius: 1px;
  }
`;

const fromOutStyle = {

  // transform: `translate(0, -50%)`,
  top: 10,
  minWidth: 0,
  minHeight: 0,
};

const hideHandle = {
  minWidth: 0,
  minHeight: 0,
};

export default memo(({ data, selected }) => {
  return (
    <Node selected={selected} className="nowheel" style={{ maxHeight: 70, overflow: "auto" }}>
      <Handle type="target" position="right" style={fromOutStyle} id="from-others"/>
      <Handle type="target" position="top" style={hideHandle} id="selfpoint"/>
        {data.label}
      <Handle type="source" position="right" style={hideHandle}/>
    </Node>
  );
});