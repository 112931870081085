import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import * as React from 'react';
import ReactFlow, {
  Node,
  useNodesState,
  useEdgesState,
  useReactFlow,
  addEdge,
  Connection,
  Edge,
  PanOnScrollMode,
  MiniMap,
  Controls,
  MarkerType,
  useNodesInitialized,
  ReactFlowProvider
} from 'reactflow';
import DDARelationCard from './DDARelationCard';


// this is important! You need to import the styles from the lib to make it work
import 'reactflow/dist/style.css';

import './Flow.css';

import CustomNode from './TextUpdaterNode.js';
import ButtonNode from './PlayButtonNode.js';
import './text-updater-node.css';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme, lightTheme } from './theme';
import{
  Fade,
  Popper,
  Paper,
} from '@mui/material';
// import ClickAwayListener from '@mui/base/ClickAwayListener';
import { ClickAwayListener } from '@mui/base';
import MainCard from '../cards/MainCard';
import CustomEdge from './CustomEdge';
import CustomConnectionLine from './CustomConnectionLine';
import TemporaryDrawer from './menuButton';
import { CSVToArray } from './CSVParser';
import { toSvg } from 'html-to-image';
import usePictureInPicture from 'react-use-pip'
// import { SmartStepEdge } from '@tisoap/react-flow-smart-edge'

const nodeTypes = {
  custom: CustomNode,
  play: ButtonNode,
};

// const edgeTypes = {
//   custom: CustomEdge,
// };

const ReactFlowStyled = styled(ReactFlow)`
  background-color: ${(props) => props.theme.bg};
`;

const MiniMapStyled = styled(MiniMap)`
  background-color: ${(props) => props.theme.bg};

  .react-flow__minimap-mask {
    fill: ${(props) => props.theme.minimapMaskBg};
  }

  .react-flow__minimap-node {
    fill: ${(props) => props.theme.nodeBg};
    stroke: none;
  }
`;

function downloadImage(dataUrl) {
  const a = document.createElement('a');

  a.setAttribute('download', 'reactflow.svg');
  a.setAttribute('href', dataUrl);
  a.click();
}

const ControlsStyled = styled(Controls)`
  button {
    background-color: ${(props) => props.theme.controlsBg};
    color: ${(props) => props.theme.controlsColor};
    border-bottom: 1px solid ${(props) => props.theme.controlsBorder};

    &:hover {
      background-color: ${(props) => props.theme.controlsBgHover};
    }

    path {
      fill: currentColor;
    }
  }
`;

const initialNodes: Node[] = [

  {
    id: '1',
    data: { label: "A: Hello Everyone! " },
    position: { x: 250, y: 10 },
    style: { width: 300 },
    type: 'custom',
    draggable: false,
  },
];

const initialEdges: Edge[] = [
  // { id: 'e1-2', source: '1', target: '2', animated: true },
  // { id: 'e1-3', source: '1', target: '3', animated: true },
];

function convertTimeToSeconds(timeString) {
  if (timeString === '' || timeString === undefined){
    return 0.0;
  } else {
    console.log('not null, timeString: ', timeString);
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    return totalSeconds;
  }
}

const edge_cross_detection = (existNodesDict, existEdges, newSourceY, newTargetY) => {
 
  const offset = 20;
  let maxOffset = 0;
  let overlapped_offsets = [];

  for (let i = 0; i < existEdges.length; i += 1) {
    const Ys = [existNodesDict[existEdges[i].source].position.y, existNodesDict[existEdges[i].target].position.y].sort();
    
    const condition1 = Math.min(...Ys) > Math.max(newSourceY, newTargetY);
    const condition2 = Math.min(newSourceY, newTargetY) > Math.max(...Ys);

    if (condition1 || condition2) {
      // console.log('no overlap');
    } else {
      if(existEdges[i].pathOptions.offset >= maxOffset){
        maxOffset = existEdges[i].pathOptions.offset;
      }
      overlapped_offsets.push(existEdges[i].pathOptions.offset);
    }
    
  }

  for(let j = 20; j < maxOffset; j += offset){
    if(!overlapped_offsets.includes(j)){
      return j;
    }
  }
  return maxOffset+offset;
};

const allowedExtensions = ["csv"];
const localstorageName = 'dda-localstorage';

function Flow({ initialNodes, initialEdges }) {

  const inputFile = useRef(null);
  const checkpointFile = useRef(null);
  const videoFile = useRef(null);

  const [selectedNode, setSelectedNode] = useState(null);
  // const [selectedPlayNode, setSelectedPlayNode] = useState(null);
  const { setViewport } = useReactFlow();
  // It state will contain the error when
  // correct file extension is not used
  //eslint-disable-next-line
  const [error, setError] = useState("");
   
  // It will store the file uploaded by the user
  // const [file, setFile] = useState("");  
  
  const [menuState, setMenuState] = useState(false);  
  const toggleDrawer = () => {
    setMenuState((open) => (open === true ? false : true));
  };
  // console.log("initialNodes: ", initialNodes);
  const [nodeInfo, setNodeInfo] = useState(initialNodes);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges); 
  const [saveFlag, setSaveFlag] = useState(0);
  const nodesInitialized = useNodesInitialized();

  // you can access the internal state here
  const reactFlowInstance = useReactFlow();
  const [rfInstance, setRfInstance] = useState(null);  

  const [allHeightDef, setAllHeightDef] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
      
      // console.log('nodesInitialized: ', nodesInitialized);
      // const allInitNodes = reactFlowInstance.getNodes();

      const nodeHeights = nodes.map((node) => (node.height));

      // console.log('nodes after nodesInitialized nodeHeights: ', nodeHeights);
      if (nodeHeights.every((heightvalue) => heightvalue !== undefined)){
        setAllHeightDef(true);
        // console.log("all true, here's nodeHeights: ", nodeHeights);
      } else {
        setAllHeightDef(false);
      }

    }, 1000);

  // eslint-disable-next-line
  }, [reactFlowInstance.viewportInitialized]);

  useEffect(() => {
    if(allHeightDef){

      const newNodePositions = rearrangeNodes(nodes);

      const rearrangeNodesDicts = nodeInfo.map((node, index) => ({...node, position: newNodePositions[index]}));
      setNodes([]);
      // setEdges([]);
      setNodes(rearrangeNodesDicts);
    }
  // eslint-disable-next-line
  }, [allHeightDef]);


  useEffect(() => {
    onSave();
    // eslint-disable-next-line
  }, [saveFlag]);

  const rearrangeNodes = (nodes: Node[]) => {

    let prev_position = nodes[0].position;
    let prev_height = nodes[0].height;
    const firstPosition = prev_position;

    const second2LastPosition = nodes.slice(1).map((node) => {

      const newPosition = {
        x: node.position.x,
        y: prev_position.y + prev_height + 25,
      };
      prev_position = node.position;
      prev_height = node.height;

      return newPosition;
    });
    return [firstPosition].concat(second2LastPosition);
  };

  const onCSVUploadClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();

  };
  const onJSONUploadClick = () => {
    // `current` points to the mounted file input element
    checkpointFile.current.click();

  }; 

  const onVideoUploadClick = () => {
    videoFile.current.click();
  };

  const handleCSVUploadFileChange = (e) => {
    setError("");
     
    // Check if user has entered the file
    if (e.target.files.length) {
        const inputFileOBJ = e.target.files[0];
         
        // Check the file extensions, if it not
        // included in the allowed extensions
        // we show the error

        const fileExtension = inputFileOBJ?.type.split("/")[1];
        // console.log('fileExtension: ', fileExtension);
        if (!allowedExtensions.includes(fileExtension)) {
            return;
        }

        // If input type is correct set the state
        inputFile.current = inputFileOBJ;
        
    }

    const reader = new FileReader();
     
    // Event listener on reader when the file
    // loads, we parse it and set the data.
    // If user clicks the parse button without
    // a file we show a error
    if (!inputFile.current) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.


    reader.onload = function(e) {
          const text = e.target.result;
          // console.log(text);
          processCSV(text)
    }

    reader.readAsText(inputFile.current);
  };

  const handleJsonCheckpoinUploadFileChange = async (e) => {
    setError("");
     
    // Check if user has entered the file
    if (e.target.files.length) {
        const inputFileOBJ = e.target.files[0];
         
        // Check the file extensions, if it not
        // included in the allowed extensions
        // we show the error

        const fileExtension = inputFileOBJ?.type.split("/")[1];
        // console.log('fileExtension: ', fileExtension);
        if (fileExtension !== 'json' && fileExtension !== 'JSON') {
            return;
        }

        // If input type is correct set the state
        checkpointFile.current = inputFileOBJ;
        
    }

    const reader = new FileReader();
     
    // Event listener on reader when the file
    // loads, we parse it and set the data.
    // If user clicks the parse button without
    // a file we show a error
    if (!checkpointFile.current) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.

    reader.readAsText(checkpointFile.current);
    reader.onload = function(e) {
      const jsonObj = e.target.result;
      // console.log(text);
      
        const flow = JSON.parse(jsonObj);

        if (flow) {
          const { x = 0, y = 0, zoom = 1 } = flow.viewport;
          setNodes(flow.nodes || []);
          setEdges(flow.edges || []);
          setViewport({ x, y, zoom });
        }
    };
    
  };  

  
  const [videoSrc, setVideoSrc] = useState('');
  const handleVideoUploadFileChange = (e) => {
    setError("");
     
    // Check if user has entered the file
    if (e.target.files.length) {
        const inputFileOBJ = e.target.files[0];
         
        // Check the file extensions, if it not
        // included in the allowed extensions
        // we show the error

        const fileExtension = inputFileOBJ?.type.split("/")[1];

        if (fileExtension !== 'mp4' && fileExtension !== 'MP4') {
            return;
        }

        // If input type is mp4, then set the URL
        const blobUrl = URL.createObjectURL(inputFileOBJ);

        setVideoSrc(blobUrl);

    }
    
  };

  const processCSV = (str, delim=',') => {
    console.log("in processCSV.");
    
    // const headers = str.slice(0,str.indexOf('\n')).split(delim);
    // const rows = str.slice(str.indexOf('\n')+1).split('\n');
    const rows = CSVToArray(str, delim);


    const newArray = rows.map((row) => ([row[0], row[3]]));
    const timeArray = rows.map((row) => ([row[1], row[2]]));

    const newNodes = newArray.map((row, index) => ({
      id: `${index}`,
      data: { label: row.join(": ") },
      position: { x: 250, y: (110+25)*(index+1) },
      style: { width: 300, height: 110 },
      type: 'custom',
      draggable: false,
      deletable: false,
    }));
    // here we assume the format of the time stamp is `hh:mm:ss`, since this format has been provided by the SI unit transcripts.


    const newButtonNodes = timeArray.map((row, index) => ({
      id: `playbuttonnode-${index}`,
      data: { start: convertTimeToSeconds(row[0]), end: convertTimeToSeconds(row[1]) },
      position: { x: 190, y: (110+25)*(index+1) + 31 },
      type: 'play',
      draggable: false,
      deletable: false
    }));

    const allNodes = [...newNodes, ...newButtonNodes];

    setNodes(allNodes);
    setNodeInfo(allNodes);
    

  };

  const edgeTypes = useMemo(() => ({ special: CustomEdge }), []);
  

  const onConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  // ---------------popover related------------------
  // const [selectedTags, setSelectedTags] = useState([]);
  // const [selectedMemo, setSelectedMemo] = useState();
  const selectedTags = useRef([]);
  const selectedMemo = useRef('');

  const [selectedEdgeID, setSelectedEdgeID] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();


  const handleClickPop = (newPlacement, edge, event) => {
    event.stopPropagation();
    console.log("handleClickPop, edge: ", edge);
    const tags = edge.data.data.split(", ");
    // setSelectedTags(tags);
    // setSelectedMemo(edge.data.memo);  
    selectedTags.current = tags;
    selectedMemo.current = edge.data.memo;  
    setSelectedEdgeID(edge.id);
    console.log("event.currentTarget: ", event.currentTarget);
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };
  const handleNewEdgePop = (newPlacement, edgeInfoDict, event) => {
    event.stopPropagation();
    console.log("handleClickPop, edgeInfoDict: ", edgeInfoDict);
    
    // setSelectedTags([]);
    // setSelectedMemo(edgeInfoDict.data.memo);
    selectedTags.current = [];
    selectedMemo.current = edgeInfoDict.data.memo;
    setSelectedEdgeID(edgeInfoDict.id);
    console.log("event.currentTarget: ", event.currentTarget);
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
    setSaveFlag(saveFlag+1);
  };  

  const handleClosePop = () => {
    setOpen(false);
    // setSelectedTags([]);
    // setSelectedEdgeID('');
    selectedTags.current = [];
    selectedMemo.current = '';
    setAnchorEl(null);
  };

  const handleTagsChangeSubmit = () => {
      // setSelectedTags(newTags);

      const tagls = selectedTags.current;
      const tagstr = tagls.join(", ");
      const memostr = selectedMemo.current;
      console.log('tagls: ', tagls);
      console.log('tagstr: ', tagstr);
      console.log('memostr: ', memostr);
      console.log('selectedMemo.current');
      const updatedEdges = edges.map((edge) => (edge.id === selectedEdgeID ? {...edge, data: {data: tagstr, laybelX: '', labelY: '', memo: memostr}} : edge));
      setEdges(updatedEdges);
      console.log("selectedEdgeID: ", selectedEdgeID);
      console.log("updatedEdges: ", updatedEdges);
      // setSelectedTags([]);
      // setSelectedMemo();

      handleClosePop();
      setSaveFlag(saveFlag+1);
      selectedTags.current = [];
      selectedMemo.current = '';      
  };
  // ---------------END popover related------------------
  // ---------------Download checkpoint------------------

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    console.log('in downloadFile');
    a.dispatchEvent(clickEvt);
    a.remove();
  }  
  const handledownloadJSON = (e) => {
    console.log("in handledownloadJSON");
    if (rfInstance) {
      const flow = rfInstance.toObject();
      const flowStr = JSON.stringify(flow);
      localStorage.setItem('dda-react', flowStr);
      downloadFile({
        data: flowStr,
        fileName: 'DDA-annotation-checkpoint.json',
        fileType: 'text/json',
      });          
    }
  };
  // ---------------END download checkp------------------
  const updateNodeSelection = (event, node) => {
    if(node.type === 'play'){
      // do the play media logic
      const startTime = parseFloat(node.data.start);
      const stopTime = parseFloat(node.data.end);
      if (videoRef.current) {
        videoRef.current.currentTime = startTime;
        videoRef.current.play();
        setTimeout(() => {

        }, (stopTime - startTime) * 1000);
      }

    } else {
      node.selected = true;
      if(selectedNode === null){
        setSelectedNode(node);
      } else {
        // add extra edge direction check logic
        
        const customized_labelXY = {'data': '', 'labelX': 11, 'labelY': 20, 'memo': ''};

        if (selectedNode.id !== node.id){

          let sourceNodeID = node.id;
          let targetNodeID = selectedNode.id;
          if (parseInt(selectedNode.id) > parseInt(node.id) ){
            sourceNodeID = selectedNode.id;
            targetNodeID = node.id;
          }
          const edgeId = 'e'+sourceNodeID+'-'+targetNodeID;

          const currentNodes = reactFlowInstance.getNodes();
          const existNodesDict = Object.fromEntries(currentNodes.map(eNode => [eNode.id, eNode]));
          const offset = edge_cross_detection(existNodesDict, edges, selectedNode.position.y, node.position.y);
          // const offset = 20 * (overlaps+1);
          const new_edge = {'id': edgeId, 
            'source': sourceNodeID,
            'target': targetNodeID,
            'type': 'special',
            'targetHandle': 'from-others',
            'data': customized_labelXY,
            'labelBgPadding': [8, 4],
            'labelBgBorderRadius': 4,
            'borderRadius': 4,
            'pathOptions': {'offset': offset},
            'labelBgStyle': { 'fill': '#FFCC00', 'color': '#fff', 'fillOpacity': 0.5 },
            'markerEnd': {
              'type': MarkerType.Arrow,
            }
          };

          const updated_edges = addEdge(new_edge, edges);
          setEdges(updated_edges);
          handleNewEdgePop('right-start', {id: edgeId, data: customized_labelXY}, event);
        } else {
          const edgeId = 'e'+node.id+'-'+selectedNode.id;
          const new_edge = {'id': edgeId, 
            'source': node.id,
            'target': selectedNode.id,
            'type': 'special',
            'targetHandle': 'selfpoint',
            'data': customized_labelXY,
            'labelBgBorderRadius': 4,
            'borderRadius': 4,
            'pathOptions': {'offset': 10},
            'labelBgPadding': [4, 4],
            'labelBgStyle': { 'fill': '#FFCC00', 'color': '#fff', 'fillOpacity': 0.7 },
            'markerEnd': {
              'type': MarkerType.Arrow,
            },            
          };
   
          const updated_edges = addEdge(new_edge, edges);
          
          setEdges(updated_edges);
          handleNewEdgePop('right-start', {id: edgeId, data: customized_labelXY}, event);        
        }

        
        
        setSelectedNode(null);
        node.selected = false;
      }
    }
  };
  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      localStorage.setItem(localstorageName, JSON.stringify(flow));
    }
  }, [rfInstance]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const flow = JSON.parse(localStorage.getItem(localstorageName));

      if (flow) {
        const { x = 0, y = 0, zoom = 1 } = flow.viewport;
        setNodes(flow.nodes || []);
        setEdges(flow.edges || []);
        setViewport({ x, y, zoom });
      }
    };

    restoreFlow();
    //eslint-disable-next-line
  }, [setNodes, setViewport]);


  const downLoadImg = () => {
    toSvg(document.querySelector('.react-flow'), {
      filter: (node) => {
        // we don't want to add the minimap and the controls to the image
        if (
          node?.classList?.contains('react-flow__minimap') ||
          node?.classList?.contains('react-flow__controls')
        ) {
          return false;
        }

        return true;
      },
    }).then(downloadImage);
  };

  // ------------------START of PIP------------------
  const videoRef = useRef(null);
  const {
    isPictureInPictureActive,
    isPictureInPictureAvailable,
    togglePictureInPicture,
  } = usePictureInPicture(videoRef);
  // ------------------END of PIP------------------

  return (
    <div className="Flow">
      <ReactFlowStyled
        nodes={nodes}
        panOnScrollMode={PanOnScrollMode.Vertical}
        panOnScroll={true}
        onNodesChange={onNodesChange}
        edges={edges}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        nodeTypes={nodeTypes}
        onNodeClick={updateNodeSelection}
        edgeTypes={edgeTypes}
        onEdgeClick={(e, edge) => handleClickPop('right-start', edge, e)}
        // defaultEdgeOptions={defaultEdgeOptions}
        connectionLineComponent={CustomConnectionLine}
        onInit={setRfInstance}
        // connectionLineStyle={connectionLineStyle}
      >
        <MiniMapStyled />
        <ControlsStyled />
      </ReactFlowStyled>
      <ClickAwayListener onClickAway={handleTagsChangeSubmit}>
        <Popper open={open} anchorEl={anchorEl} placement={placement} onClose={handleClosePop} transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper>
                        <MainCard content={false} >
                            <DDARelationCard
                                curTags={selectedTags}
                                curMemo={selectedMemo}
                                // setSelected={setSelectedTags}
                                // setNewMemo={setSelectedMemo}
                                // handleCancel={handleClosePop}
                                // handleSubmit={handleTagsChangeSubmit}
                            />
                        </MainCard>
                    </Paper>
                </Fade>
            )}
        </Popper>
      </ClickAwayListener>
      <button onClick={toggleDrawer} style={{ position: 'absolute', zIndex: 100, left: 10, top: 10 }}>
        menu
      </button>

      <TemporaryDrawer
        menuState={menuState} 
        toggleDrawer={toggleDrawer}
        csvuploadClick={onCSVUploadClick}
        jsonuploadClick={onJSONUploadClick}
        downloadJSON={handledownloadJSON}
        uploadVideo={onVideoUploadClick}
      />
      <div >
        <video ref={videoRef} key={videoSrc} autoPlay={false} controls loop >
          <source src={videoSrc} type="video/mp4"/>
        </video>
        {isPictureInPictureAvailable && (
          <button style={{position: 'absolute', zIndex: 101, left: 253, top: 10 }}
            onClick={() => togglePictureInPicture(!isPictureInPictureActive)}
          >
            {isPictureInPictureActive ? 'Disable' : 'Enable'} Picture in Picture
          </button>
        )}
      </div>
      <button onClick={onRestore} style={{ position: 'absolute', zIndex: 100, left: 66, top: 10 }}>restore</button>
      <button onClick={downLoadImg} style={{ position: 'absolute', zIndex: 100, left: 130, top: 10 }}>Download Image</button>
      <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={handleCSVUploadFileChange}/>
      <input type='file' id='jsonfile' ref={checkpointFile} style={{display: 'none'}} onChange={handleJsonCheckpoinUploadFileChange}/>
      <input type='file' id='videofile' ref={videoFile} style={{display: 'none'}} onChange={handleVideoUploadFileChange}/>
    </div>
  );
}

// export default Flow;
const App = () => {
  const [mode, setMode] = useState('light');
  const theme = mode === 'light' ? lightTheme : darkTheme;


  // const initialNodess = initialEdges;
  // const initialEdgess = initialEdges;
//eslint-disable-next-line
  const toggleMode = () => {
    setMode((m) => (m === 'light' ? 'dark' : 'light'));
  };  
  return (
    <ThemeProvider theme={theme}>
      {/*<FloatingActionButtons />*/}
       {/*<button onClick={toggleMode} style={{ position: 'absolute', zIndex: 100, left: 10, top: 10 }}>
        switch mode
      </button>*/}
    <ReactFlowProvider>
      <Flow initialNodes={initialNodes} initialEdges={initialEdges}/>
    </ReactFlowProvider>
    </ThemeProvider>
  );
};

export default App;
