import React, { memo } from 'react';
import { Handle } from 'reactflow';
import styled from 'styled-components';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

const Node = styled.div`
  // margin: auto;
  // display: block;
  border-radius: 50%;
  // background: ${(props) => props.theme.nodeBg};
  color: ${(props) => props.theme.nodeColor};
  border: 1px solid ${(props) => (props.selected ? props.theme.primary : props.theme.nodeBorder)};
  width: 48px;
  height: 48px;
`;

const fromOutStyle = {

  // transform: `translate(0, -50%)`,
  top: 10

};

export default memo(({ data, selected }) => {
  return (
    <Node selected={selected} className="nowheel" style={{  display: "flex", justifyContent: "center" }} starttime={data.start}>
      <PlayCircleFilledIcon style={{  display: "flex", justifyContent: "center", margin: "auto" }}/>
    </Node>
  );
});