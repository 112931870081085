import React, { FC } from 'react';
import { EdgeProps, getBezierPath, EdgeLabelRenderer, getSmoothStepPath, useEdges, useNodes, EdgeText } from 'reactflow';
// import { EdgeText } from '@react-flow/core';

// const edge_cross_detection = (existNodes, existEdges, newSourceY, newTargetY) => {
  
//   const existNodesDict = Object.fromEntries(existNodes.map((eNode) => [eNode.id, {
//       'sourcePosition': eNode[Symbol.for("internals")].handleBounds.source[0],
//       'targetPositionOther': eNode[Symbol.for("internals")].handleBounds.target[0], 
//       'targetPositionSelf': eNode[Symbol.for("internals")].handleBounds.target[1]
//     }]
//   ));
//   let overlaps = -1;
//   for (let i = 0; i < existEdges.length; i += 1) {
//     const edgeSourceY = existNodesDict[existEdges[i].source].sourcePosition.y;
//     console.log("in edge_cross_detection, edgeSourceY: ", edgeSourceY);
//     if (existEdges[i].source !== existEdges[i].target) {
//       const edgeTargetY = existNodesDict[existEdges[i].target].targetPositionOther.y;
//       console.log("in edge_cross_detection, edgeTargetY: ", edgeTargetY);
//       console.log("newSourceY: ", newSourceY, " | newTargetY: ", newTargetY);
//       const condition1 = newSourceY >= edgeSourceY && newSourceY <= edgeTargetY;
//       const condition2 = newTargetY >= edgeSourceY && newTargetY <= edgeTargetY;
//       if (condition1 || condition2) {
        
//         overlaps += 1;
//         console.log("yes, overlap!", overlaps);
//       }
//     }
//   }
//   return overlaps;
// };

const edge_cross_detection = (existNodesDict, existEdges, newSourceY, newTargetY) => {
 
  let overlaps = 0;
  for (let i = 0; i < existEdges.length; i += 1) {
    const Ys = [existNodesDict[existEdges[i].source].position.y, existNodesDict[existEdges[i].target].position.y].sort();
    

    const condition1 = newSourceY >= Ys[0] && newSourceY <= Ys[1];
    const condition2 = newTargetY >= Ys[0] && newTargetY <= Ys[1];
    if (condition1 || condition2) {
      overlaps += 1;
    }
    
  }
  return overlaps;
};
const foreignObjectSize = 40;

const onEdgeClick = (evt, id) => {
  evt.stopPropagation();
  alert(`remove ${id}`);
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  pathOptions,
  borderRadius,
  markerEnd,
  markerStart,
  labelBgStyle
}) => {
  // const all_nodes = useNodes();
  // const all_edges = useEdges();
  // console.log("all_edges: ", all_edges);
  // console.log("all_nodes: ", all_nodes);
  // console.log('pathOptions: ', pathOptions);
  // console.log("data:" , data);
  // console.log("id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition", id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition);
  // console.log("pathOptions, borderRadius, markerEnd, markerStart, labelBgStyle: ", pathOptions, borderRadius, markerEnd, markerStart, labelBgStyle);
  // console.log("existNodes[0]['Symbol(internals)'].handleBounds: ", all_nodes[0][Symbol.for("internals")]);
  // const existNodesDict = Object.fromEntries(all_nodes.map(eNode => [eNode.id, eNode]));

  // const overlaps = edge_cross_detection(existNodesDict, all_edges, sourceY, targetY);
  
  // const borderRadius = 5;
  // const centerX = 1
  // const centerY = 
  // console.log("sourceX: ", sourceX, "sourceY: ", sourceY);
  // console.log("targetX: ", targetX, "targetY: ", targetY);
  // const offset = overlaps === -1 ? 10 : 12 * (overlaps+1);
  const offset = pathOptions? pathOptions.offset : 5;

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    // borderRadius,
    offset
  });

  // const [edgeTags, setEdgeTags] = useState(data);
  // const [edgeID, setEdgeID] = useState(id);

  const labelXX = data.labelX;
  const labelYY = data.labelY;

  // console.log("labelXX: ", data.labelX);
  // console.log("labelYY", data.labelY);

  const shiftLabelY = labelY - 20;
  const targetHandleSelfFlag = offset === 10;
  // console.log("targetHandle: ", targetHandle);
  const edgeID = `edge${id}`;
  const shiftLabelX = labelX - 50;
  // const labelTransfStr = targetHandleSelfFlag?`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`:`translate(-50%, -50%) rotate(90deg) translate(${labelX}px,${labelY}px)`;
  const transformDict = targetHandleSelfFlag?{}:{transform: [{rotate: '90deg'}]};
  // console.log(labelTransfStr);
  const secondTrans = targetHandleSelfFlag?"":"rotate(90deg)";
  const fontOffset = data.length / 2 * 6;
  return (
    <>
      <path id={id} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd} markerStart={markerStart}/>
        {targetHandleSelfFlag && (<EdgeLabelRenderer>
                 <div
                  id={edgeID}
                  style={{
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${shiftLabelX}px,${labelY-5}px)`,
                    // transform: `translate(-50%, -50%) translate(${labelX+fontOffset}px,${labelY+20}px)`,
                    // transform: secondTrans,
                    // transform: labelTransfStr,
                    background: labelBgStyle,
                    padding: 5,
                    borderRadius: 5,
                    fontSize: 10,
                    fontWeight: 100,
                  }}
                  className="nodrag nopan"
                >
                  {data.data}
                </div> 
          
              </EdgeLabelRenderer> 
        )}
       {/*<EdgeText
        x={labelX}
        y={labelY}
        label="a label"
        labelStyle={{ fill: 'white' }}
        labelShowBg
        labelBgStyle={{ fill: 'blue' }}
        labelBgPadding={[2, 4]}
        labelBgBorderRadius={2}
        style={{transform: "rotate(90deg)"}}
      />; */}
       { !targetHandleSelfFlag && (<text style={{ fontSize: 10, fontWeight: 100 }}>
                  <textPath href={`#${id}`}  startOffset="20%" >
                    {data.data}
                  </textPath>
                </text> 
        )}   

    </>
  );
};

export default CustomEdge;